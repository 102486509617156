/**
 * This ensures that when "apply" is clicked on the filter form, it copies the
 * values from the search bar form to the filter form before submitting.
 */
const register = () => {
    const searchBarForm: HTMLFormElement = document.querySelector('form[name="product_search_simple"]');
    const filterForm: HTMLFormElement = document.querySelector('form[name="product_search"]');

    if (searchBarForm && filterForm) {
        filterForm.addEventListener('submit', (event) => {
            const filterFormSubmitButton = filterForm.querySelector('#product_search_submit') as HTMLButtonElement;

            // only run if the filter form submit button is clicked
            if (!event.submitter || event.submitter !== filterFormSubmitButton) {
                return;
            }

            event.preventDefault();

            const inputs = {
                searchBarForm: {
                    query: searchBarForm.querySelector('#product_search_simple_query') as HTMLInputElement,
                    profession: searchBarForm.querySelector('#product_search_simple_profession') as HTMLSelectElement,
                    professionSearchTerm: searchBarForm.querySelector('#product_search_simple_professionSearchTerm') as HTMLInputElement,
                    state: searchBarForm.querySelector('#product_search_simple_state') as HTMLSelectElement,
                },
                filterForm: {
                    query: filterForm.querySelector('#product_search_query') as HTMLInputElement,
                    profession: filterForm.querySelector('#product_search_profession') as HTMLSelectElement,
                    professionSearchTerm: filterForm.querySelector('#product_search_professionSearchTerm') as HTMLInputElement,
                    state: filterForm.querySelector('#product_search_state') as HTMLSelectElement,
                }
            }

            inputs.filterForm.query.value = inputs.searchBarForm.query.value;
            if (inputs.filterForm.profession) {
                inputs.filterForm.profession.value = inputs.searchBarForm.profession.value;
            }
            inputs.filterForm.professionSearchTerm.value = inputs.searchBarForm.professionSearchTerm.value;
            inputs.filterForm.state.value = inputs.searchBarForm.state.value;

            if (filterForm.reportValidity()) {
                filterForm.submit();
            }
        });
    }
}

export { register }
