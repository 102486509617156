type Action = "add" | "checkout" | "enroll" | "unenroll";
type LinkStyles = "add" | "checkout" | "processing" | "enroll" | "unenroll";

interface Product {
    id: number;
    fqcn: string;
}

interface ItemFromCartContents {
    id: number;
    entityFQCN: string;
}

let activeEnrollmentCourseIds = [];

/**
 * Defines the multiple states of cart action links.
 */
const defaultLinkValues = {
    "add": { // when the item is not in the cart
        iconHTML: `<svg style="pointer-events: none;" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-plus"><path d="M5 12h14"/><path d="M12 5v14"/></svg>`,
        label: "Add to cart",
        classNames: ['!animate-none', '!text-white', "!animate-none"],
        enabled: true,
        opacity: 1,
    },
    "checkout": { // when the item is in the cart - changed to just go to checkout screen
        iconHTML: `<svg style="pointer-events: none;" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-shopping-cart"><circle cx="8" cy="21" r="1"/><circle cx="19" cy="21" r="1"/><path d="M2.05 2.05h2l2.66 12.42a2 2 0 0 0 2 1.58h9.78a2 2 0 0 0 1.95-1.57l1.65-7.43H5.12"/></svg>`,
        label: "Checkout Now",
        classNames: ["!bg-green-500", "hover:!bg-green-400", "!text-white", "!shadow-none", "!animate-none"],
        enabled: true,
        opacity: 1,
    },
    "processing": { // when the cart is processing an action
        iconHTML: `<svg style="pointer-events: none;" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-loader-circle animate-spin"><path d="M21 12a9 9 0 1 1-6.219-8.56"/></svg>`,
        label: "",
        classNames: ["!bg-gray-300", "!text-slate-300", '!animate-pulse', '!text-white'],
        enabled: false,
        opacity: 1,
    },
    "enroll": { // when an active member is not enrolled in the course
        iconHTML: `<svg style="pointer-events: none;" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-plus"><path d="M5 12h14"/><path d="M12 5v14"/></svg>`,
        label: "Enroll Now",
        classNames: ['!animate-none', '!text-white', "!animate-none"],
        enabled: true,
        opacity: 1,
    },
    "unenroll": {
        // when an active member is enrolled in the course - they can't unenroll
        // for now, this will just take the user to their course enrollment page
        // with the course highlighted
        iconHTML: `<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-play-icon lucide-play"><polygon points="6 3 20 12 6 21 6 3"/></svg>`,
        label: "Start Course",
        classNames: ["!bg-green-500", "hover:!bg-green-400", "!text-white", "!shadow-none", "!animate-none"],
        enabled: true,
        opacity: 1,
    }
}

/**
 * Handles the cart action event. This is usually triggered by a click event on
 * a cart action link.
 * @param link
 * @param action
 * @param items
 */
const handleActionEvent = (link: HTMLElement, action: Action, items: Product[]): void => {
    configureLink(link, "processing" as LinkStyles);
    if (action === "enroll") {
        const nextAction = action === "enroll" ? "unenroll" : "enroll";
        fetch(`/enrollment/add`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                items: items,
            }),
        })
            .then((response) => {
                if (!response.ok) {
                    throw new Error("Failed to enroll");
                }
                return response.json();
            })
            .then(() => {
                setTimeout(() => {
                    configureLink(link, nextAction);
                }, 200);
            })
            .catch((error) => {
                console.error("Failed to enroll", error);
                configureLink(link, nextAction);
            });
    } else if (action === "unenroll") {
        // we need to just take the user to their courses page
        window.location.href = `/user/courses?highlight=${link.dataset.courseIds}`;
    } else if (action === "checkout") {
        // we need to just take the user to the cart page
        window.location.href = `/cart`;
    } else if (action === "add") {
        const nextAction = action === "add" ? "checkout" : "add";
        fetch(`/cart/${action}`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                items: items,
            }),
        })
            .then((response) => {
                if (!response.ok) {
                    throw new Error("Failed to add item to cart");
                }
                return response.json();
            })
            .then((data) => {
                // added
                setTimeout(() => {
                    updateCartCountIndicator(data.cart.itemCount);
                    configureLink(link, nextAction);
                }, 200);
            })
            .catch((error) => {
                console.error("Failed to add item to cart", error);
                configureLink(link, action);
            });
    }
}

/**
 * This function configures the link with the appropriate styles and attributes
 * based on the configured defaultLinkValues above.
 * @param link
 * @param style
 */
const configureLink = (link: HTMLElement, style: LinkStyles): void => {
    const escapedFQCN = link.dataset.cartEntityFqcn?.replace(/\\/g, "\\\\");
    const selectorText = `[data-cart-entity-id="${link.dataset.cartEntityId}"][data-cart-entity-fqcn="${escapedFQCN}"]`;
    const allMatchingLinks = document.querySelectorAll(selectorText) as NodeListOf<HTMLElement>;

    allMatchingLinks.forEach((link: HTMLElement) => {
        const defaultValues = defaultLinkValues[style];
        link.innerHTML = `${defaultValues.iconHTML} <span style="pointer-events: none;">${defaultValues.label}</span>`;
        link.dataset.cartAction = style;

        link.classList.remove(...Object.values(defaultLinkValues).map((value) => value.classNames).flat());
        if (defaultValues.classNames.length > 0) {
            link.classList.add(...defaultValues.classNames);
        }

        if (defaultValues.enabled) {
            link.dataset.allowClick = "true";
        } else {
            link.dataset.allowClick = "false";
        }

        // handle opacity with an important flag
        link.style.opacity = defaultValues.opacity.toString();
    });
}

/**
 * This function extracts the action, entityId, and entityFQCN from the link
 * element's attributes.
 * @param link
 */
const getLinkValues = (link: HTMLElement): { action: string; entityId: number; entityFQCN: string } => {
    const action = link.dataset.cartAction || "";
    const entityId = parseInt(link.dataset.cartEntityId || "");
    const entityFQCN = link.dataset.cartEntityFqcn || "";
    return { action, entityId, entityFQCN };
}

/**
 * Updates the cart count indicator with the provided count. If the count is
 * null, it will attempt to get the count from the existing indicator
 * textContent.
 * @param count
 */
const updateCartCountIndicator = (count: number | null): void => {
    const cartCountIndicator = document.querySelector("[data-cart-count]") as HTMLElement;

    if (count === null) {
        // get the count from the existing indicator textContent
        count = parseInt(cartCountIndicator.textContent || "0");
    }

    if (cartCountIndicator) {
        cartCountIndicator.innerHTML = count.toString();

        if (count > 0) {
            cartCountIndicator.classList.add('!bg-primary')
        } else {
            cartCountIndicator.classList.remove('!bg-primary')
        }
    }
}

const isActionValid = (action: string): boolean => {
    return action === "add" || action === "checkout" ||
        action === "enroll" || action === "unenroll";
}


/**
 * Registers the cart link actions. This function will iterate over the provided
 * cartLinks and configure the link with the appropriate styles and attributes.
 * @param cartLinks
 */
const registerCartLinkActions = (cartLinks: NodeListOf<HTMLElement>) => {
    cartLinks.forEach((link) => {
        let { action } = getLinkValues(link);

        if (isActionValid(action)) {
            configureLink(link, action as Action);
        }

        link.addEventListener("click", (event) => {
            event.preventDefault();
            event.stopPropagation();

            if (link.dataset.allowClick === "false") {
                return;
            }

            let { action, entityId, entityFQCN } = getLinkValues(link);

            if (entityId === 0 || entityFQCN === "") {
                console.error("Cart action link missing data attributes", link, action, entityId, entityFQCN);
                return;
            }

            if (!isActionValid(action)) {
                console.error("Cart action link has invalid action", link, action);
                return;
            }

            let items: Product[] = [];
            items.push({ id: entityId, fqcn: entityFQCN });
            handleActionEvent(link, action as Action, items);
        });
    });
}

const userNotEnrolledInAny = (courseIds: number[]): boolean => {
    // check if there are any courseIds that are not in the activeEnrollmentIds
    // array
    const matches = courseIds.some((courseId) => activeEnrollmentCourseIds.includes(courseId));
    return !matches;
}

/**
 * This function will dynamically update cart buttons and the cart count in
 * the event we have hit back/forward in the browser history. This is necessary
 * because browser history pulls from cache and does not re-render the page with
 * the latest data.
 */
const loadInitialLinkStates = (cartLinks: NodeListOf<HTMLElement>) => {

    cartLinks.forEach((link) => {
        configureLink(link, "processing");
    });

    fetch("/cart/content", {
        method: "GET",
    })
        .then((response) => {
            if (!response.ok) {
                throw new Error("Failed to get cart content");
            }
            return response.json();
        })
        .then((data) => {
            updateCartCountIndicator(data.cart.itemCount);
            cartLinks.forEach((link) => {
                let { entityId, entityFQCN } = getLinkValues(link);
                const isItemInCart = data.cart.items.find((item: ItemFromCartContents) => item.entityFQCN === entityFQCN && item.id === entityId);
                const userIsMember = link.dataset.userIsMember === "true";
                const productCourseIds = link.dataset.courseIds.split(",").map(Number);
                if (userIsMember) {
                    if (userNotEnrolledInAny(productCourseIds)) {
                        configureLink(link, "enroll");
                    } else {
                        configureLink(link, "unenroll");
                    }
                } else {
                    if (userNotEnrolledInAny(productCourseIds)) {
                        configureLink(link, isItemInCart ? "checkout" : "add");
                    } else {
                        configureLink(link, "unenroll");
                    }
                }
            })
        })
        .catch((error) => {
            console.error("Failed to get cart content", error);
        });
}

/**
  * This function will load the active enrollments for the current user into
  * the activeEnrollments array.
  */
const loadActiveEnrollments = async () => {
    let response = await fetch("/enrollment/list", {
        method: "GET",
    })

    if (!response.ok) {
        throw new Error("Failed to get active enrollments");
    }

    let data = await response.json();
    activeEnrollmentCourseIds = [];
    for (let enrollment of data.items) {
        activeEnrollmentCourseIds.push(enrollment.course_id);
    }
}

const loadCompletedProductEnrollments = async (productIds: Array<number>) => {
    let response = await fetch("/enrollment/last-completed-by-product-ids", {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify({
            items: productIds,
        }),
    })

    if (!response.ok) {
        throw new Error("Failed to get last completed enrollments");
    }

    let data = await response.json();

    return data;
}

const updateCardsWithLastCompletedEnrollments = (completedProductIDS: Array<number>) => {
    const cartLinks = document.querySelectorAll("[data-cart-entity-id]") as NodeListOf<HTMLElement>;
    cartLinks.forEach((card) => {
        const entityId = parseInt(card.dataset.cartEntityId || "");
        const isCompleted = (completedProductIDS[entityId] !== undefined);
        if (isCompleted) {
            const closestProductCard = card.closest("[data-product-card]");
            const closestProductCardLastCompletedContainer = closestProductCard?.querySelector("[data-last-completed-container]");
            const closestProductCardLastCompletedSpan = closestProductCardLastCompletedContainer?.querySelector("[data-last-completed]");
            if (closestProductCardLastCompletedContainer && closestProductCardLastCompletedSpan) {
                const date = new Date(completedProductIDS[entityId]);
                const formattedDate = `${date.getMonth() + 1}-${date.getDate()}-${date.getFullYear()}`;
                closestProductCardLastCompletedSpan.innerHTML = formattedDate;
                closestProductCardLastCompletedContainer.classList.remove("hidden");
            }
        }
    });
}

const register = () => {
    updateCartCountIndicator(0);
    const cartLinks = document.querySelectorAll("[data-cart-action]") as NodeListOf<HTMLElement>;
    const productIds = Array.from(cartLinks).map((link) => parseInt(link.dataset.cartEntityId || ""));

    // handle calls on pageshow, so that browser back/forward button navigation
    // will still update the data
    window.addEventListener("pageshow", function() {

        loadCompletedProductEnrollments(productIds).then((data) => {
            updateCardsWithLastCompletedEnrollments(data.items);
        }).catch((error) => {
            console.error("Failed to load completed product enrollments", error);
        });

        loadActiveEnrollments().then(() => {
            loadInitialLinkStates(cartLinks);
            registerCartLinkActions(cartLinks);
        }).catch((error) => {
            console.error("Failed to load active enrollments", error);
        });
    });
}

export { register };
